<!---抽奖--实物领取成功页面---->
<template>
  <div class="app">
    <div class="xybody">
      <div class="swiper">
        <img :src="rows.img_url" class="imgSwiper" alt="" />
      </div>
      <div class="nav">
        <div class="tit">{{ rows.prize_name }}</div>
        <div class="tit_en">{{ rows.prize_name_en }}</div>
        <div class="subtit">提交成功，我们将尽快为您邮寄礼品</div>
        <div class="subtit">Your prize will be delivered soon</div>
      </div>
      <div class="ul">
        <div class="li">
          <div class="label">
            <div class="zh">姓名</div>
            <div class="en">Name</div>
          </div>
          <div class="value">{{ rows.send_username }}</div>
        </div>
        <div class="li">
          <div class="label">
            <div class="zh">手机号码</div>
            <div class="en">Tel</div>
          </div>
          <div class="value">{{ rows.send_phone }}</div>
        </div>
        <div class="li">
          <div class="label">
            <div class="zh">所在区域</div>
            <div class="en">City</div>
          </div>
          <div class="value">
            {{ rows.send_province }}{{ rows.send_city }}{{ rows.send_area }}
          </div>
        </div>
        <div class="li">
          <div class="label">
            <div class="zh">详细地址</div>
            <div class="en">Address</div>
          </div>
          <div class="value">{{ rows.send_addr }}</div>
        </div>
        <div class="li">
          <div class="label">
            <div class="zh">领取时间</div>
            <div class="en">Lottery Time</div>
          </div>
          <div class="value">{{ rows.add_time }}</div>
        </div>
      </div>
    </div>
    <div class="footer_btn">
      <div class="btn_cancel back_list" @click="$router.replace('/H5_luckdraw_new')">
        <span class="btn_one_ch">再次抽奖</span>
        <span class="btn_one_en">Try Again</span>
      </div>
      <div class="btn_confirm back_orderlist" @click="$router.replace('/H5_luckdrawlist_new')">
        <span class="btn_one_ch">查看抽奖记录</span>
        <span class="btn_one_en">Check Record</span>
      </div>
    </div>
  </div>
</template>
<script>
var qs = require("qs");
export default {
  data() {
    return {
      rows: {},
      is_double: -1, //0正常模式，>0是双倍模式
    };
  },
  methods: {
  },
  mounted() {
    this.is_double = localStorage.getItem("is_double");
    this.$axios({
      method: "post",
      url: this.$url + "/Api/Score/prize_detail",
      data: qs.stringify({
        id: this.$route.query.id,
        ut: localStorage.getItem("ut"),
      }),
    }).then((res) => {
      console.log(res);
      if (res.data.code == 1) {
        this.rows = res.data.data;
      }
    });
  },
};
</script>
<style scoped >
@import"../../assets/H5.css";

.luckbg {
  width: 100%;
}

.swiper {
  min-height: 516px;
  background: #fff;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.imgSwiper {
  display: block;
  margin: 0 auto;
  width: 454px;
  height: 454px;
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  flex-direction: column;
}

.tit {
  font-size: 32px;
  line-height: 45px;
  color: #111A34;

  font-weight: bold;
}

.tit_en {
  font-size: 24px;
  line-height: 33px;
}

.subtit {
  font-size: 28px;
  line-height: 28px;
  margin-top: 24px;
  color: #858B9C;
}

.subtit_en {
  font-size: 22px;
  color: #858B9C;
}

.xybody {

  height: calc(100% - 100px);
  overflow: auto;
  padding-bottom: 190px;
}

.ul {
  box-sizing: border-box;
  margin-top: 48px;
  padding: 0 30px;
}

.li {
  box-sizing: border-box;
  width: 690px;
  height: 88px;
  margin-bottom: 16px;
  background: #EDEDFA;
  border-radius: 16px 16px 16px 16px;
  display: flex;
  justify-content: center;
  padding: 0 30px;
  line-height: 1;
}

.label {
  width: 240px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #111A34;
  font-weight: 400;
}

.zh {
  font-size: 26px;
}

.en {
  font-size: 18px;
  margin-top: 6px;
}

.value {
  width: calc(100% - 240px);
  line-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
  box-sizing: border-box;
  font-size: 26px;
  color: #111A34;
}

.footer_btn {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  padding: 24px 30px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid#EDEDFA;
  ;
}

.footer_btn div {
  width: 335px;
  height: 72px;
  line-height: 1;
  border-radius: 30px 4px 30px 4px;
}

.footer_btn .btn_cancel {
  background: #fff;
  border: 1px solid #02005D;
}

.footer_btn .btn_cancel .btn_one_ch {
  font-size: 26px;
  font-weight: bold;
  color: #02005D;
}

.footer_btn .btn_cancel .btn_one_en {
  font-size: 12px;
  color: #02005D;
  margin-top: 4px;
}

.footer_btn .btn_confirm {
  background: #02005D;
}

.footer_btn .btn_confirm .btn_one_ch {
  font-size: 26px;
  font-weight: bold;
  color: rgba(255, 255, 255, 1);
}

.footer_btn .btn_confirm .btn_one_en {
  font-size: 16px;
  margin-top: 4px;
  color: rgba(255, 255, 255, 1);
}</style>
